<template>
  <article class="event flex flex--wrap" ref="container">
    <loader v-if="loading" :loading-message="loadingMessage" />

    <branded-divider v-if="index > 0" class="wide" />

    <div class="image col-50">
      <image-loader
        v-if="inViewport"
        :image-url="event.eventImage"
        :alt="event.name"
      />
      <span class="spinner" v-else />
    </div>

    <div class="description col-50">
      <event-subheader :event="event" heading-class="h5" />

      <!-- Main Teacher -->
      <teacher-info :instructor="event.assignedTeacher" />

      <!-- Substitute Teacher -->
      <teacher-info
        v-if="activeUser && !isStudentUser"
        is-substitute
        :instructor="event.backupTeacher"
      />

      <!-- Description -->
      <div class="content" v-html="event.description" />

      <!-- Controls -->
      <event-action-buttons :event="event" @cancel-registration="unregister" />
    </div>
  </article>
</template>

<script>
import { elemInView } from "../utilities";
import BrandedDivider from "./BrandedDivider.vue";
import EventActionButtons from "./EventActionButtons.vue";
import EventSubheader from "./EventSubheader.vue";
import ImageLoader from "./ImageLoader.vue";
import Loader from "./Loader.vue";
import RegistrationsMixin from "./mixins/registrations.mixin";
import TeacherInfo from "./TeacherInfo.vue";

export default {
  name: "EventListItem",

  components: {
    BrandedDivider,
    EventActionButtons,
    EventSubheader,
    TeacherInfo,
    ImageLoader,
    Loader
  },

  mixins: [RegistrationsMixin],

  props: { event: Object, index: { type: Number, default: 0 } },

  data: () => ({ showTeacherInfo: false, permissions: [], inViewport: false }),

  mounted() {
    // Load image when list-item is in viewport
    this.checkElemInView();
    if (!this.inViewport) {
      window.addEventListener("scroll", this.loadImageWhenInView, true);
    }
  },

  beforeDestroy() {
    if (!this.inViewport) {
      window.removeEventListener("scroll", this.loadImageWhenInView, true);
    }
  },

  methods: {
    /** @description Assert that component bounding rect is on-screen */
    checkElemInView() {
      const boundingRect = this.$refs.container.getBoundingClientRect();
      this.inViewport = elemInView(boundingRect);
    },

    /** @description Remove event listener when component bounding rect is on-screen */
    loadImageWhenInView() {
      this.checkElemInView();
      if (this.inViewport) {
        window.removeEventListener("scroll", this.loadImageWhenInView, true);
      }
    }
  }
};
</script>

<style lang="scss" src="./EventListItem.scss"></style>
