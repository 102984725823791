/* eslint-disable no-undef */
import { getAllPublicClasses, getAllPublicEvents } from "@/models/events";
import {
  eventFilters,
  filterByDateAndDay,
  filterEventsList
} from "@/helpers/classes-events.helpers";
import EventsMixin from "./events.mixin";
import { utcFromISO } from "@/helpers/date-time.helpers";

/**
 * Mixin for components that manage a list of `Events`
 */
const EventsListMixin = {
  name: "EventsListMixin",

  mixins: [EventsMixin],

  data() {
    return {
      loading: true,
      dateFilter: null,
      filtered: [],
      filteredInWeek: [],
      list: [],
      listFilter: new Set(),
      scrollY: 0,
      page: 1,
      count: 5
    };
  },

  computed: {
    eventFilters() {
      const assigned = new RegExp("^Assigned");
      const registered = new RegExp("^Registered");
      const f = eventFilters(this.type).map(i => i.trim());
      if (this.activeUser) {
        return this.isStudentUser ? f.filter(t => !assigned.test(t)) : f;
      }
      return f.filter(t => !assigned.test(t) && !registered.test(t));
    },

    filtersActive() {
      return this.listFilter.size > 0 || this.dateFilter !== null;
    },

    listLegend() {
      if (!this.activeUser) return [];

      const state = this.list.reduce(
        (agg, e) => {
          agg.Weekly = e.isRepeatingEvent;
          agg.Substitute = e.backupTeacher.id === this.activeUser.id;
          agg["Primary Teacher"] = e.assignedTeacher.id === this.activeUser.id;
          return agg;
        },
        {
          "Primary Teacher": false,
          Substitute: false,
          Weekly: false
        }
      );

      const legend = Object.keys(state).filter(k => state[k]);
      return legend;
    },

    searchLabel() {
      const { dateFilter } = this;
      return dateFilter
        ? `${dateFilter.toFormat("DD")} (${dateFilter.toFormat("ZZZZ")})`
        : [...this.listFilter].join(", ");
    },

    searchLabelClass() {
      const noResults = !this.filtered.length;
      return { "success--text": !noResults, "error--text": noResults };
    },

    showScrollToTop() {
      return this.scrollY > 50;
    },

    today() {
      const nowISO = new Date().toISOString();
      const tz = this.activeUser ? this.activeUser.timeZone : this.localTZ;
      return utcFromISO(nowISO, tz);
    },
    pages() {
      const length = this.dateFilter
        ? this.filteredInWeek.length
        : this.filtered.length;
      const totalPages = Math.ceil(length / this.count);
      return new Array(totalPages).fill(0).map((x, i) => i + 1);
    },
    /** Separate list for 'day of' vs 'week of' searches */
    filteredData() {
      const first = this.page * this.count;
      const last = first - this.count;
      return this.filtered.slice(last, first);
    },
    /** Separate list for 'week of' vs 'day of' searches */
    filteredDataWeek() {
      const first = this.page * this.count;
      const last = first - this.count;
      return this.filteredInWeek.slice(last, first);
    }
  },

  async mounted() {
    const getEventsList = this.isOnlineClass
      ? getAllPublicClasses
      : getAllPublicEvents;

    document.title = `${this.type} • All • ${APP_ORG} Admin`;
    try {
      this.list = await getEventsList();
      this.filterList();
    } catch (error) {
      this.onViewError(error);
    } finally {
      this.loading = false;
      window.addEventListener("scroll", this.updateWindowScrollY, true);
    }
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.updateWindowScrollY, true);
  },

  methods: {
    applyFilters(filters) {
      // New object reference
      this.listFilter = new Set([...filters]);
      this.filterList(this.list);
    },
    clearFilters() {
      this.dateFilter = null;
      this.filteredInWeek = [];
      this.listFilter = new Set();
      this.filterList();
    },
    filterList() {
      const src = [...(this.dateFilter ? this.filteredInWeek : this.list)];
      this.filtered = filterEventsList(this.activeUser, src, this.listFilter);
    },
    onDateFilter(date) {
      this.clearFilters();
      this.dateFilter = date;
      this.filtered = filterByDateAndDay(this.list, date);
      this.filteredInWeek = filterByDateAndDay(this.list, date);
    },
    scrollToTop() {
      window.scroll({ top: 0 });
    },
    updateWindowScrollY() {
      this.scrollY = window.scrollY;
    },
    setPage(page) {
      this.page = page;
    }
  }
};

export default EventsListMixin;
